@import "src/_variables";

.page {
  padding: 83px 0 0;

  .image img {
    width: 100%;
    margin: 26px 0 18px;
  }

  .button {
    text-align: center;
    margin: 0 auto 80px;
  }

  button svg {
    margin-left: 3px;
    vertical-align: -3px;
  }

  .sectionTitle, .title, .metadata, .caption, .shareActions {
    padding: 0 20px;
  }

  @media (min-width: #{$breakpointDesktop}) {
    padding-top: 154px;
    max-width: 986px;
    margin: 0 auto;

    .sectionTitle, .title, .metadata, .caption, .shareActions {
      padding: 0;
    }
  }
}

.sectionTitle {
  color: $color_night;
  font-family: $highlightFontFamily;
  letter-spacing: 0.17em;
  text-transform: uppercase;
  line-height: 1.5rem;;
  font-size: 1rem;
}

.title {
  margin: 10px 0 9px;
  font-size: 2.625rem;
  color: $darkBlue;
  letter-spacing: -0.2px;
  font-family: $lightFontFamily;
  line-height: 2.875rem;
}

.metadata {
  font-family: $defaultFontFamily;
  color: $primaryTextColor;
  font-size: 1.125rem;
  line-height: 1.25rem;

  .date {
    padding-right: 5px;

    &:after {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $primaryTextColor;
      display: inline-block;
      position: relative;
      bottom: 3px;
      left: 5px;
    }
  }

  .author {
    padding-left: 5px;
  }
}

.shareActions {
  margin-top: 23px;
  font-size: 0;

  > span {
    margin: 0 10px 0 0;

    &:last-child {
      margin-right: 0;
  }
  }

  img {
    width: 26px;
    height: 26px;
  }
}

.caption {
  font-family: $defaultFontFamily;
  line-height: 1.125rem;
  letter-spacing: -0.2px;
  color: $primaryTextColor;
}

.content {
  margin-top: 37px;
  margin-bottom: 80px;
  padding: 0 36px;
  font-family: $defaultFontFamily;
  font-size: 1.25rem;
  line-height: 2.125rem;
  letter-spacing: -0.01em;
  color: $primaryTextColor;

  h3 {
    color: $darkBlue;
    font-size: 2.125rem;
    line-height: 2.75rem;
    font-weight: normal;
    font-family: $defaultFontFamily;
    margin: 3rem 0 0.5rem;
  }

  @media (min-width: #{$breakpointDesktop}) {
    padding: 0 92px;
  }
}
