html {
  font-size: 16px;
}

::-moz-selection {
  /* Code for Firefox */
  background: rgba(200, 246, 235, 0.5);
}

::selection {
  background: rgba(200, 246, 235, 0.5);
}

hr {
  margin: 10px auto;
  padding: 0 68px;
  border-top: 1px solid black !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: "TWK Lausanne", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
