@import "src/_variables";

.signUpSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 110px 10px;
  background-image: url('../../assets/img/circle_arrow_background.png');
  background-repeat: no-repeat;
  background-size: cover;

  .title {
    font-family: $highlightFontFamily;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.17em;
    text-transform: uppercase;
    color: $blue;
    margin: 0;
  }

  .subTitle {
    font-family: $lightFontFamily;
    font-size: 3rem;
    line-height: 3.5rem;
    letter-spacing: -0.2px;
    color: $blue;
    margin: 27px 0 34px;
  }


  @media (min-width: #{$breakpointDesktop}) {
    margin-top: 30px;
  }
}
