@import "src/_variables";

$breakpointHeader: 768px;
$headerHeight: 71px;
$backgroundColor: #0E6959;

.logoLink {
  display: flex;
}

.logo {
  height: 32px;
}

.dropdownMenu {
  box-shadow: 0px 4px 8px rgba(1, 11, 26, 0.12);
  border-radius: 12px;
  border: none;
  min-width: 145px;
  padding: 0;

  a {
    padding: 8px 12px;
    font-size: 0.875rem;
    color: $night;

    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  a:hover {
    color: $white;
    background-color: $aubergine;
  }
}

.dropdown {
  padding: 0 !important;
  display: inline-block;
  height: 24.5px;
}

.dropdownToggle {
  padding: 10px 0 10px;
}

.dropdownToggle::after {
  display: none;
}

.dropdownIcon {
  margin-left: 4px;
  color: $pacific;
  vertical-align: middle;
}

.desktopNavMenuItem {
  font-size: 0.875rem;
  font-family: "TWK Lausanne", sans-serif;
  margin-right: 40px;
  padding: 21px 0 20px;
  cursor: pointer;
  text-decoration: none;
  display: none;

  &:last-child {
    margin-right: 0;
  }

  > a, 
  > a:visited, 
  > a:focus,
  > a:hover,
  > a:active {
    color: $white;
    text-decoration: none;
  }

  > .dropdown {
    > a {
      color: $white;
      text-decoration: none;
    }
  }

  &.navMenuBubble {
    background-color: $blue;
    color: $white;
    border-radius: 20px;
    padding: 5px 20px;
    margin: 15px 0;
    height: 32px;
  }

  @media (min-width: #{$breakpointHeader}) {
    display: block;
  }
}

.navMenuLink {
  color: $white;
}

.navMenuDropdown {
  background-color: $white;
  padding: 24px 0 42px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  box-shadow: 6px 6px 19px 0 rgba(0,0,0,0.16);
  display: flex;
  flex-direction: column;

  a, a:visited, a:focus, a:hover, a:active {
    color: $night;
    text-decoration: none;
  }

  button {
    width: 100%;
  }

  .button {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttons {
    padding: 0 50px;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    align-self: flex-end;
  }

  @media (min-width: #{$breakpointHeader}) {
    display: none;
  }
}

.navMenuDropdownHeader {
  display: flex;
  padding: 0 24px 40px 24px;
}

.closeButton {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $night;
  border-radius: 50%;
  font-size: 1.25rem;
}

.navMenuDropdownItems {
  text-align: left;
  overflow: scroll;

  a, a:visited {
    color: $night;
  }
}

.navMenuDropdownItem {
  margin-bottom: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 11px 24px;

  .caret {
    transition: transform 100ms;
  }

  &.expanded {
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12);

    .caret {
      transform: rotate(90deg);
    }
  }
}

.mobileSubMenu {
  height: 0;
  overflow: hidden;

  .navMenuDropdownItem {
    padding-left: 44px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  &.expanded {
    height: auto;
  }
}

.mobileMenuButton {
  font-size: 1.5rem;
  display: flex;
  align-items: flex-start;

  @media (min-width: #{$breakpointHeader}) {
    display: none;
  }
}
