@import "src/_variables";

.custom-toggle__link {
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #fff;
}

.dropdownIcon {
  margin-left: 4px;
  color: $springgreen;
  vertical-align: middle;
}