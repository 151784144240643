@import "src/_variables";

.grid {
  margin: 0 20px;
  border-top: 1px solid $color_sidewalk;
  padding: 25px 0;

  @media (min-width: #{$breakpointDesktop}) {
    border-top: none;
    max-width: $breakpointDesktop;
    margin: 0 auto;
  }
}

.articles {
  @media (min-width: #{$breakpointDesktop}) {
    display: flex;
    flex-wrap: wrap;
    max-width: $breakpointDesktop;
  }
}

.title {
  font-size: 0.875rem;
  font-family: $highlightFontFamily;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: $primaryTextColor;
  letter-spacing: 0.17em;
}

.article {
  border-top: 1px solid $color_sidewalk;
  display: flex;
  padding-top: 20px;
  display: none;

  &:nth-child(1), &:nth-child(2), &:nth-child(3) {
    display: flex;
  }

  img {
    order: 2;
    width: 120px;
    height: 120px;
    margin-top: 29px;
    margin-left: 26px;
    object-fit: cover;
    flex-shrink: 0;
  }

  .date {
    font-family: $defaultFontFamily;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.005em;
    color: $primaryTextColor;
  }

  .title {
    font-family: $defaultFontFamily;
    color: $darkBlue;
    letter-spacing: 0.0025em;
    font-size: 1.375rem;
    line-height: 1.75rem;
    text-transform: none;
    margin: 10px 0;

    a, a:visited {
      color: $darkBlue;
    }
  }

  .snippet {
    font-size: 1.125rem;
    line-height: 1.5625rem;
    color: $primaryTextColor;
    font-family: $defaultFontFamily;
  }

  .footer {
    margin: 12px 0 33px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: $defaultFontFamily;

    .author {
      color: $blue;
      padding-right: 8px;

      &:after {
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $primaryTextColor;
        display: inline-block;
        position: relative;
        bottom: 3px;
        left: 8px;
      }
    }

    .readDuration {
      padding-left: 8px;
      color: $primaryTextColor;
    }
  }

  &:first-child {
    border-top: none;
  }


  @media (min-width: #{$breakpointDesktop}) {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 290px;
    margin-right: 45px;
    border-top: none;

    &:nth-child(3n) {
      margin-right: 0;
    }

    img {
      order: 0;
      width: 290px;
      height: 189px;
      margin: 0 0 23px;
    }
  }
}

.grid.highlight {
  background-color: #F5F7FF;
  margin: 0;
  border-top: none;
  padding: 20px 30px 90px;

  @media (min-width: #{$breakpointDesktop}) {
    border-top: none;
    max-width: $breakpointDesktop;
    margin: 0 auto;
  }

  .title {
    margin-bottom: 16px;
  }

  .article {
    border-top: none;
    padding-top: 24px;

    img {
      display: none;
    }

    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      display: flex;
    }

    .highlightIndex {
      background-color: $darkBlue;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: $white;
      font-size: 1.125rem;
      letter-spacing: -0.2px;
      line-height: 1.7rem;
      text-align: center;
      flex-shrink: 0;
      font-family: $semiBoldFontFamily;
      margin-right: 14px;
    }

    .title {
      font-size: 1.125rem;
      letter-spacing: -0.2px;
      line-height: 1.375rem;
      margin-top: 3px;
      margin-bottom: 6px;
    }

    .link {
      font-size: 0.875rem;
    }
  }

  @media (min-width: #{$breakpointDesktop}) {
    background-color: transparent;

    .articles {
      background-color: #F5F7FF;
      padding: 26px 34px 50px;
    }

    .article {
      width: 254px;
      margin-right: 65px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      .highlightIndex {
        display: none;
      }

      img {
        display: block;
        width: 254px;
        height: 145px;
      }
    }
  }
}