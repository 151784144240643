@import "src/_variables";

.link > a {
  font-family: $defaultFontFamily;
  text-decoration: none;
  cursor: pointer;
}

.link.inverse > a {
  color: $white;
}

.icon {
  margin-left: 10px;
  display: inline-block;
  transition: transform 240ms ease-in-out;
}

.link.withIcon:hover {
  .icon {
    transform: translateX(10px);
  }
}
