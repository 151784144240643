$breakpointDesktop: 1304px;
$breakpointTablet: 768px;
$breakpointHorizontalTablet: 1024px;
$headerOffset: 93px;
$mobileHeaderOffset: 71px;
$headerHeight: 81px;
$maxContentWidth: 1248px;

$columns: 12;
$tabletColumns: 8;
$gutterWidth: 28px;
$columnWidth: 72px;
$tabletColumnWidth: 92px;
$tabletGutterWidth: 32px;

$smallItemBorderRadius: 12px;
$largeItemBorderRadius: 40px;

/* COLORS */
// $white: #fff;
$black: #000;
$blue: #043683;
$lightBlue: #00D7F1;
$primaryTextColor: #424242;
$primaryBackgroundColor: #F2F2F2;
$secondaryBackgroundColor: #FAFAFA;
$borderBlue: #3467FF;
$color_night: #212121;
$color_sidewalk: #BDBDBD;
$darkBlue: #043683;
$oasis: #043683;
$night: #212121;
$persimmon: #EE6C4D;
$solitude: #E6EBF3;
$fog: #F6F6F6;
$cove: #6886B5;
$pacific: #00A5D6;
$pacific2: #5A98D1;
$oxford: #021B42;

// PRIMARY
$emerald: #0E6959;
$concrete: #F2F2F2;
$white: #FFFFFF;

// SECONDARY
$springgreen: #ABE629;
$aubergine: #38003E;
$night: #212121;

// TERTIARY
$seafoam: #C8F6EB;
$forest: #00494F;
$mustard: #EBB600;

// ACCENT
$raspberry: #B51851;
$ruby: #DB2165;
$grape: #741B47;

$defaultFontFamily: 'TWK Lausanne', sans-serif;
$primaryFontFamily: 'Wulkan Display', serif;
$secondaryFontFamily: 'TWK Lausanne', sans-serif;


// $defaultFontFamily: GalanoGrotesque-Regular, sans-serif;
$lightFontFamily: 'TWK Lausanne', sans-serif;
$mediumFontFamily: 'TWK Lausanne', sans-serif;
$semiBoldFontFamily: 'TWK Lausanne', sans-serif;
// $secondaryFontFamily: GalanoGrotesque-Regular, sans-serif;
$highlightFontFamily: 'TWK Lausanne', sans-serif;


$tileBoxShadow: 6px 6px 19px 0 rgba(0,0,0,0.16);
$tileBoxShadowHover: 11px 11px 24px 0 rgba(0,0,0,0.26);
$highlightImageHeightMobile: 480px;
$highlightImageWidthMobile: 450px;
$headerZIndex: 1000;

$mobileImageWidth: 312px;
$mobileImageHeight: 416px;
$tabletImageWidth: 312px;
$tabletImageHeight: 416px;
$imageHeight: 644px;
$imageWidth: 484px;
$sectionBottomMargin: 60px;
$sectionBottomMargin_desktop: 120px;
$imageBorderRadius: 12px;
