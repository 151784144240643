@import 'src/_variables';

.content {
  height: 100vh;
  position: relative;
  top: 12px;
  width: auto;
  padding: 0 32px 0;
  overflow: auto;
  border-top-right-radius: $largeItemBorderRadius;
  border-bottom-left-radius: $largeItemBorderRadius;
  border-bottom-right-radius: $largeItemBorderRadius;
  border-top-left-radius: 0;

  @media (min-width: #{$breakpointTablet}) {
    width: auto;
    height: auto;
    padding: 0;
    top: 0;
    margin: 32px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: #{$breakpointHorizontalTablet}) {
    flex-direction: row;
    display: inline-flex;
    align-items: flex-start;
    height: auto;
    top: 120px;
    width: auto;
    margin: 0 auto;
  }
}

.backdrop {
  background: rgba(255,255,255, 0.98);
}

.closeButton {
  position: absolute;
  right: 32px;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $fog;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (min-width: #{$breakpointHorizontalTablet}) {
    position: relative;
    right: 0;
    top: 0;
    margin-left: 40px;
    margin-top: 40px;
  }

  &.mobileBottomButton {
    position: relative;
    right: 0;
    align-self: center;
    margin: 40px auto 80px;

    @media (min-width: #{$breakpointTablet}) {
      display: none;
    }
  }
}

.image {
  margin-bottom: 12px;

  img {
    object-fit: cover;
    border-radius: $smallItemBorderRadius;
    width: 256px;
    height: 342px;

    @media (min-width: #{$breakpointDesktop}) {
      width: 270px;
      height: 360px;
    }
  }

  @media (min-width: #{$breakpointTablet}) {
    margin-right: 20px;
    margin-bottom: 0;
  }

  @media (min-width: #{$breakpointHorizontalTablet}) {
    margin-bottom: 12px;
    margin-right: 28px;
  }
}

.linkedInLink {
  svg {
    g {
      fill: $night;
    }
  }
}

.holder {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  :global(.footnote) {
    margin-bottom: 12px;
  }

  @media (min-width: #{$breakpointTablet}) {
    align-items: flex-start;
    flex-direction: row;
    width: 536px;
  }

  @media (min-width: #{$breakpointHorizontalTablet}) {
    flex-direction: column;
    margin-bottom: 0;
    width: 298px;
  }
}

.bio {
  max-width: 536px;
  padding: 32px 16px 32px 32px;
  border-radius: $largeItemBorderRadius;
  background-color: $fog;
  margin: 0 -32px;

  @media(min-width: #{$breakpointTablet}) and (max-width: #{$breakpointHorizontalTablet - 1}) {
    height: calc(100vh - 40px - 342px - 64px);
  }

  @media (min-width: #{$breakpointTablet}) {
    max-height: 584px;
    margin: 0;
    overflow: hidden;
  }

  @media (min-width: #{$breakpointHorizontalTablet}) {
    max-height: calc(100vh - 240px);
    height: auto;
  }

  > div {
    padding: 0 16px 0 0;

    @media(min-width: #{$breakpointTablet}) and (max-width: #{$breakpointHorizontalTablet - 1}) {
      height: calc(100vh - 40px - 342px - 64px - 64px);
    }

    @media (min-width: #{$breakpointTablet}) {
      max-height: calc(584px - 64px);
      overflow: auto;
    }

    @media (min-width: #{$breakpointHorizontalTablet}) {
      max-height: calc(100vh - 240px - 64px);
      height: auto;
    }

    &::-webkit-scrollbar {
      width: 10px;
      right: 40px;
      position: relative;
      background-color: rgba(0, 0, 0, 0);
      -webkit-border-radius: 100px;
    }

    &::-webkit-scrollbar:hover {
      background-color: rgba(0, 0, 0, 0.09);
    }

    &::-webkit-scrollbar-thumb:vertical {
      background: rgba(33, 33, 33, 0.5);
      -webkit-border-radius: 100px;
      background-clip: padding-box;
      border: 2px solid rgba(0, 0, 0, 0);
      min-height: 10px;
    }

    &::-webkit-scrollbar-thumb:vertical:active {
      -webkit-border-radius: 100px;
    }
  }
}
