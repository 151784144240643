@import 'src/_variables';

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $headerZIndex + 10;
}

.modalBackdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.modalContent {
  width: calc(100% - 68px);
  position: relative;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  background-color: $white;
  border-radius: 21px;
  padding: 2rem;
  box-sizing: border-box;

  button {
    width: 100%;
    margin-top: 12px;
  }

  @media (min-width: #{$breakpointDesktop}) {
    width: 343px;
  }
}

.closeButton {
  color: #fff;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  position: relative;
  top: 100px;
  border: solid 2px white;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}

.modalEnter {
  .modalBackdrop {
    opacity: 0;
  }

  .modalContent {
    opacity: 0;
    transform: translateY(-10%) translateX(-50%);
  }

  .closeButton {
    opacity: 0;
    transform: translateY(-10%);
  }
}

.modalEnterActive {
  .modalBackdrop {
    opacity: 1;
    transition: all 0.5s;
  }

  .modalContent {
    opacity: 1;
    transform: translateY(0%) translateX(-50%);
    transition: all 0.5s;
  }

  .closeButton {
    opacity: 1;
    transform: translateY(0%);
    transition: all 0.5s;
  }
}

.modalExit {
  .modalBackdrop {
    opacity: 1;
  }

  .modalContent {
    opacity: 1;
    transform: translateY(0%) translateX(-50%);
  }

  .closeButton {
    opacity: 1;
    transform: translateY(0%);
  }
}

.modalExitActive {
  .modalBackdrop {
    opacity: 0;
    transition: all 0.5s;
  }

  .modalContent {
    opacity: 0;
    transform: translateY(-10%) translateX(-50%);
    transition: all 0.5s;
  }

  .closeButton {
    opacity: 0;
    transform: translateY(-10%);
    transition: all 0.5s;
  }
}
