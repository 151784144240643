
@import "src/_variables";

.page {
  padding-top: 83px;

  @media (min-width: #{$breakpointDesktop}) {
    padding-top: 158px;
  }
}

.sectionTitle {
  color: $color_night;
  font-family: $highlightFontFamily;
  text-align: center;
  letter-spacing: 0.17em;
  text-transform: uppercase;
  line-height: 1.5rem;;
  font-size: 1rem;
}

.title {
  margin: 10px auto 36px;
  font-size: 2.625rem;
  color: $darkBlue;
  letter-spacing: -0.2px;
  font-family: $lightFontFamily;
  text-align: center;
  padding: 0 32px;
  line-height: 2.875rem;

  @media (min-width: #{$breakpointDesktop}) {
    margin: 16px auto 85px;
    line-height: 3.5rem;
    max-width: 360px;
  }
}

.featuredArticle {
  padding: 0 0 50px;

  img {
    width: 100%;
    margin-bottom: 1rem;
  }

  .date {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $primaryTextColor;
    font-family: $defaultFontFamily;
    padding: 0 21px;
  }

  .title {
    font-family: $mediumFontFamily;
    font-size: 1.75rem;
    line-height: 2.1875rem;
    text-align: left;
    padding: 0 21px;
    margin: 13px 0 20px;
  }

  .snippet {
    font-family: $defaultFontFamily;
    font-size: 1.25rem;
    line-height: 2.125rem;
    letter-spacing: -0.01em;
    color: $primaryTextColor;
    padding: 0 21px;
  }

  .link {
    margin: 37px 0 0;
    padding: 0 21px;
    font-size: 1.3125rem;
  }

  @media (min-width: #{$breakpointDesktop}) {
    display: flex;
    max-width: $breakpointDesktop;
    margin: 0 auto;

    img {
      width: 637px;
      margin-right: 37px;
    }

    .date, .title, .snippet, .link {
      padding: 0;
    }
  }
}
