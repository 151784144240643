@import "src/_variables";

.page {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  z-index: $headerZIndex + 10;
  background-color: white;
  min-width: 25rem;
  overflow: hidden;
}

.iframeContainer {
  overflow: hidden;

  iframe {
    border: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
  }
}