@import 'src/_variables';

.topSection {
  padding: 140px 0 40px;

  h1 {
    margin-bottom: 40px;
  }

  @media (min-width: #{$breakpointTablet}) {
    padding: 200px 0 80px;
  }
}

.bottomSection {
  &:global(.section.fullWidthMobile) {
    margin-bottom: 0;
    padding-bottom: 60px;

    @media (min-width: #{$breakpointTablet}) {
      padding-bottom: 120px;
    }
  }
}